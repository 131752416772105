import {addChildRoute, addRoute, addRoutes } from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import AppOrderList from "./views/AppOrderList.vue";
import ProductListingPage from "@pazion/pazion-catalog/src/views/product/ProductListingPage.vue";

addRoutes(getCatalogRoutes());

addRoute({
    path: '/orders',
    name: 'orders',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        roles: ['user']
    },
});

addRoute({
    path: '/returns',
    name: 'returns',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': true,
        roles: ['user']
    },
});

addChildRoute('settings',{
    path: 'settings/products-new',
    name: 'Products new',
    component: ProductListingPage,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': false,
        roles: ['user']
    }
});

addChildRoute('settings',{
    path: 'settings/delivery-classification',
    name: 'Delivery classifications',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': true,
        roles: ['user']
    }
});

addRoute({
    path: '/products',
    name: 'products',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': true,
        roles: ['user']
    },
});

addChildRoute('settings',{
    path: 'settings/delivery-code',
    name: 'Delivery codes',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': true,
        roles: ['user']
    }
});

addChildRoute('settings',{
    path: 'reconciliate-csv-import',
    name: 'Reconciliate CSV',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        'redirect-to-backend': true,
        roles: ['user']
    }
});

import {postRequest} from "@pazion/pazion-core/src/services/auth-service";


export async function updateOrderLineWarehouse(orderLine: number, warehouse: string) {
	return (await postRequest("update-order-line", {orderLine, warehouse})).data;
}

//cancelRequest: 'accept','reject'

export async function cancelOrderLine(orderLine: number, accept: string) {
	if (accept !== "accept") {
		accept = "reject";
	}
	return (await postRequest("cancel-order-line", {orderLine, "cancelreq": accept})).data;
}

export async function retryOrderLine(orderLine: number) {
	return (await postRequest("retry-order-by-order-line", {orderLine})).data;
}

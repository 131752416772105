
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {IOrderLine} from "@pazion/pazion-catalog/src/models/order";
import {IWarehouse} from "@pazion/pazion-catalog/src/models/warehouse";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import {updateOrderLineWarehouse} from "../services/catalog-service";

@Component({
	components: {
		PazTooltipButton
	}
})
export default class WarehouseSelector extends Vue {

	@Prop()
	public item!: IOrderLine;

	@Prop({default: false})
	private isLoading: boolean = false;

	@Prop()
	public warehouses!: IWarehouse[];

	public selectedValue: string = "";

	private responseWarehouse: string = "";

	mounted() {
		this.selectedValue = this.getWarehouseName;
		this.responseWarehouse = this.selectedValue;
	}

	public async saveWarehouse() {

	  this.$emit("update-loading", true);
	  const rsp = await updateOrderLineWarehouse(this.item.id, this.selectedValue);
	  this.$emit("update-loading", false);

		if (rsp.success) {
			this.responseWarehouse = rsp.warehouse;
		}
	}

	updateLoading(loading:boolean){
		this.isLoading=loading;
	}

	get getWarehouseName() {

	  if (this.responseWarehouse !== "")
			return this.responseWarehouse;

		return this.item?.orderLineAttributes.warehouse;
	}

	get editWarehouseName()
  {
	  if(this.selectedValue === undefined)
		  	return true;

	  if(this.item.orderLineStatus == 'NEW'
		  		|| this.item.orderLineStatus == 'READY_TO_SHIP')
		  return true;

	  	return false;
	}
}


import {Component, Vue} from "vue-property-decorator";
import OrderLineGroupByListing from "@pazion/pazion-catalog/src/views/orders/OrderLineGroupByListing.vue";
import {getWarehouses} from "@pazion/pazion-catalog/src/services/catalog-service";
import {timestampToYMD} from "@pazion/pazion-catalog/src/helpers/format";
import {IWarehouse} from "@pazion/pazion-catalog/src/models/warehouse";
import WarehouseSelector from "../components/WarehouseSelector.vue";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import OrderLineCancel from "../components/OrderLineCancel.vue";
import PazOrderStatus from "@pazion/pazion-catalog/src/components/order/PazOrderStatus.vue"
import OrderLineRetry from "../components/OrderLineRetry.vue";

@Component({
	components: {
	  OrderLineGroupByListing,
		OrderLineCancel,
		PazTooltipButton,
		PazOrderStatus,
		WarehouseSelector,
		OrderLineRetry
	},
	methods: {
		timestampToYMD
	}
})

export default class AppOrderList extends Vue {

	public headers = [
		{
			text: "OrderId",
			align: "left d-none d-md-table-cell",
			class: "cell-truncate",
			cellClass: "nowrap",
			sortable: true,
			value: "orderId",
		},
		{
			text: "Orderline",
			align: "left",
			class: "cell-truncate",
			sortable: true,
			cellClass: "nowrap",
			value: "orderLineExternalId",
			groupable: false,
		},
	  {
		  text: 'Marketplace',
		  align: 'left d-none d-xs-table-cell d-lg-table-cell',
		  class: 'cell-truncate',
		  sortable: true,
		  value: null,
		  groupable: false,
	  },
		{
			text: "Description",
			align: 'left maxcol  d-none d-xs-table-cell d-md-table-cell d-lg-table-cell',
			class: "cell-truncate",
			sortable: true,
			value: "orderLineDescription",
			groupable: false,
		},
		{
			text: " Name / Attributes",
			align: 'left d-none d-lg-table-cell',
			class: 'cell-truncate',
			sortable: true,
			value: "orderLineAttributesLine",
			groupable: false,
		},
		{
			text: "Quantity",
			align: 'right d-none d-lg-table-cell',
			class: "cell-truncate d-none d-lg-table-cell",
			sortable: true,
			value: "orderLineQuantity",
			groupable: false,
		},
		{
			text: "Total",
			align: "right d-none d-xs-table-cell d-lg-table-cell",
			class: "cell-truncate text-right	mx-0",
			sortable: true,
			value: "orderLineTotal",
			groupable: false,
		},
		{
			text: "Status",
			align: "right",
			class: "cell-truncate text-right",
			sortable: true,
			value: "status",
			groupable: false,
		},
		{
			text: "Order date",
			align: "right maxcol",
			class: "cell-truncate text-right",
			sortable: true,
			value: "orderDate",
			groupable: false,
		},
		{
			text: "Actions",
			align: "right",
			class: "cell-truncate text-right mx-5",
			cellClass: "nowrap",
			sortable: false,
			value: "actions",
			groupable: false,
		}
	];

	public warehouses: IWarehouse[] = [];

	created() {
		this.fetchData();
	}

	async fetchData() {
		this.warehouses = (await getWarehouses()).filter((w) => w.isSelectable == 1);
	}

}




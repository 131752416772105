
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {IOrderLine} from "@pazion/pazion-catalog/src/models/order";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import {cancelOrderLine, retryOrderLine} from "../services/catalog-service";

@Component({
	components: {
		PazTooltipButton
	}
})
export default class OrderLineRetry extends Vue {

	@Prop()
	public item!: IOrderLine;

	private isLoading: boolean = false;
	private rsp: string = "";

	//
	public async retryOrderLine() {
		this.isLoading = true;
		const cancelRsp = await retryOrderLine(this.item.id);
		this.isLoading = false;
		this.rsp = cancelRsp.status ?? cancelRsp.error;
	}

	get canRetry() {
		if (this.item.orderLineStatus === undefined) {
			return false;
		}
		switch (this.item.orderLineStatus) {
		case "ERROR":
		case "ERROR_TO_SHIPMENT":
		case "ERROR_SHIPMENT":
		case "ERROR_INVOICE":
			return true;
		}
		return false;
	}
}

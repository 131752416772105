
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";
import {IOrderLine} from "@pazion/pazion-catalog/src/models/order";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import {cancelOrderLine} from "../services/catalog-service";

@Component({
	components: {
		PazTooltipButton
	}
})
export default class OrderLineCancel extends Vue {

	@Prop()
	public item!: IOrderLine;

	private isLoading: boolean = false;
	private rsp: string = "";

	public async acceptCancel() {
		this.isLoading = true;
		const cancelRsp = await cancelOrderLine(this.item.id, "accept");
		this.isLoading = false;
		this.rsp = cancelRsp.status ?? cancelRsp.error;
	}
}
